
import {Component, Watch} from 'vue-property-decorator';
import {services, utils} from '@/main';
import IdentityAccount from '@/components/account/IdentityAccount.vue';
import AbstractComponentVue from '../services/AbstractComponentVue';
import {IAccountInfos} from '@/services/dto/IAccountInfos';
import {IAccountService, UserServiceState, UserServiceType} from '@/services/dto/IDtos';
import DisplayEnumUtils from '@/utils/DisplayEnumUtils';
import SigIconServiceState from '@/components/user/SigIconServiceState.vue';
import AlertService from '@/utils/AlertService';
import Vue, { isShallow } from 'vue';

@Component({
	components: {
    SigIconServiceState,
		IdentityAccount,
	},
})

export default class UserList extends AbstractComponentVue {
	private accounts: IAccountInfos[] = new Array();
	private accountsFiltered: IAccountInfos[] = new Array();
	private currentPage: number = 1;
	private perPage: number = 10;
	private rows: number = 0;
	private roleFilter: string = '';
	private identityFilter: string = '';

  	private selectFunctionOptions = [
    	{text: '', value: ''},
    	{text: 'Administrateur de l\'office', value: 'ROLE_SELFCARE_ADMIN'},
    	{text: 'Collaborateur', value: 'ROLE_SELFCARE_COLLABORATEUR'},
    	{text: 'Entité', value: 'ROLE_ENTITY'},
    	{text: 'Notaire', value: 'ROLE_SELFCARE_NOTAIRE'},
	];

	private fields = [
		{ key: 'identity',
		  label: 'Identité',
		  formatter: (value: any, key: any, item: any) => {
			  return item.identity.lastName + ' ' + item.identity.firstName + ' '
			  + item.identity.login + ' ' + item.identity.mail;
		  },
		  sortable: true,
		  sortByFormatted: true,
		  thStyle: { width: '25%' },
		},
		{ key: 'role',
		  label: 'Fonction',
          sortable: true,
		  thStyle: { textAlign: 'center', width: '15%' },
		}		
	];

	private created():void {
		const thStyle: any = { textAlign: 'center', verticalAlign: 'top', width: '20%' };
		const thStyleAction:any = { verticalAlign: 'top', width: '20%'};
		if(Vue.prototype.isAdnov()){
			this.fields.push({
				key: 'adnov', 
				sortable:false,
				label: 'Proposé par ADNOV',
				thStyle:  thStyle
			});
		}

		if(Vue.prototype.isAdsn()){
			this.fields.push({ 
				key: 'adsn',
				sortable: false,
		  		label: 'Proposé par l\'ADSN',
		  		thStyle: thStyle,
			});
		}

		this.fields.push({ key: 'actions',
		  label: 'Actions',
		  sortable: false,
		  thStyle: thStyleAction,
		});
	}

	private beforeMount(): void {
		this.showModal('modal-traitement-cours');
		services.restService.callGet('/api/v1/office/current/users').then((response: any) => {
			this.accounts = response.data;
			this.accounts.forEach((account) => {
				// On ajout Ballade non actif s'il n'est pas présent
				if (account.services.filter((service) => service.serviceType === 'BALLADE' as UserServiceType).length === 0) {
					const ballade = {	serviceType: 'BALLADE' as UserServiceType,
									serviceState: 'NONE' as UserServiceState } as IAccountService;
					account.services.push(ballade);
				}

				// On ajout Logiciel de visioconférence non actif s'il n'est pas présent
				if (account.services.filter((service) => service.serviceType === 'VISIO_SOFT' as UserServiceType).length === 0) {
					const visio = { serviceType: 'VISIO_SOFT' as UserServiceType,
								  serviceState: 'NONE' as UserServiceState } as IAccountService;
					account.services.push(visio);
				}
			});
			this.accountsFiltered = this.accounts;
			this.rows = this.accountsFiltered.length;
		}).finally(() => {
			this.hideModal('modal-traitement-cours');
		});
	}

  /**
   * Création d'un pop-up de confirmation la rediffusion du mot de passe.
   * Si confirmation redirect vers la page de rediffusion
   * @param accountId Identifiant de l'utilisateur
   */
  private repostPasswordConfirmation(accountId: number): void {
    AlertService.customAlert(
        'Mot de passe oublié',
        'Les mots de passe sont personnels et confidentiels.' +
        ' La rediffusion du lien "Mot de passe oublié" au bon destinataire est placée sous votre responsabilité',
        'info',
        'Valider',
        true,
        () => {
          services.routageService.goTo('/forgetten/notaire/' + accountId);
        },
        true,
    );
  }

  /**
   * Création d'un pop-up de confirmation la rediffusion du mot de passe.
   * Si confirmation redirect vers la page de rediffusion.
   * @param accountId Identifiant de l'utilisateur
   * @param newlogin login de l'utilisateur
   */
  private generateNewActCodeConfirm(accountId: number, newlogin: string): void {
    AlertService.customAlert(
        'Générer un nouveau code d\'activation',
        'La génération d’un nouveau code d’activation invalide et remplace le code d’activation' +
        ' qui vous avait été fourni précédemment pour cet utilisateur.' +
        ' Souhaitez-vous confirmer cette action ? ',
        'info',
        'Confirmer',
        true,
        () => {
          this.newActivationCode(accountId, newlogin);
        },
        true,
    );
  }

  /**
   * Fonction qui permet de générer un nouveau mot de passe
   */
  private newActivationCode(accountId: number, newlogin: string) {
    services.restService.callGet('/api/v1/tokens/' + accountId + '/new').then((response: any) => {
      this.$router.push({
        path: '/accounts/' + accountId + '/mer/activation/new',
        query: {login: newlogin, codeActivation: response.data.token},
      });
    });
  }

	// Filtre sur le role
	@Watch('roleFilter', { immediate: true, deep: true })
	private onRoleFilterChange(newVal: any) {
		if (newVal === '') {
			this.accountsFiltered = this.accounts;
		} else {
			this.accountsFiltered = this.accounts.filter((account) => account.role === newVal);
		}
		this.rows = this.accountsFiltered.length;
	}

	// Filtre sur l'identité
	@Watch('identityFilter', { immediate: true, deep: true })
	private onIdentityFilterChange(newVal: any) {
		if (newVal === '') {
			this.accountsFiltered = this.accounts;
		} else {
			this.accountsFiltered = this.accounts.filter(
				(account) => account.identity.lastName.search(new RegExp(newVal, 'i')) > -1
							|| account.identity.firstName.search(new RegExp(newVal, 'i')) > -1
							|| account.identity.login.search(new RegExp(newVal, 'i')) > -1);
		}
		this.rows = this.accountsFiltered.length;
	}


	/**
	 * Permet d'afficher le label du service
	 */
	private getDisplayName(serviceType: string): string {
		return DisplayEnumUtils.getDisplayName(serviceType);
	}

	/**
	 * Permet d'afficher l'état du service
	 */
	private getDisplayState(serviceState: string): string {
		return DisplayEnumUtils.getDisplayState(serviceState);
	}

	private formatDate(date: Date): string {
		return utils.dateUtils.formatDate(date);
	}
}
