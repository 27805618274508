
import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({})
export default class SigIconServiceState extends Vue {
  @Prop() private state!: string;

  private get iconTabFromState(): string[] {
    if (this.state === 'ACTIVE') {
      return ['fas', 'check-circle'];
    } else if (this.state === 'IN_CREATION' || this.state === 'BEING_VALIDATED') {
      return ['far', 'check-circle'];
    } else if (this.state === 'DELETION_IN_PROGRESS') {
      return ['far', 'times-circle'];
    } else if (this.state === 'DELETED') {
      return ['fas', 'times-circle'];
    } else {
      return [];
    }
  }

  private get classFromState(): string {
    if (this.state === 'ACTIVE') {
      return 'vertActif';
    } else if (this.state === 'IN_CREATION' || this.state === 'BEING_VALIDATED') {
      return 'vertTransparent';
    } else if (this.state === 'DELETION_IN_PROGRESS') {
      return 'rougeTransparent';
    } else if (this.state === 'DELETED') {
      return 'rougeResilie';
    } else {
      return '';
    }
  }

}
